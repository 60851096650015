import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { Button, Navbar, Nav } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "./logo.png";
import Login from "./Login";

const logout = () => {
  Auth.signOut()
    .then((data) => {
      console.log(data);
      window.location = "/";
    })
    .catch((err) => console.log(err));
};

const Menu = () => {
  const [loginComponent, setLoginComponent] = useState(<div></div>);
  const [amplify, setAmplify] = useState(
    <Button
      variant="outline-success"
      onClick={() => {
        setLoginComponent(<Login />);
      }}
    >
      Login
    </Button>
  );

  useEffect(() => {
    fetch("/static/cognito.json", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("stack:", json);
        Amplify.configure(json);
        Auth.currentAuthenticatedUser()
          .then((user) => {
            console.log("user", user);
            setAmplify(
              <NavDropdown title={user.username} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            );
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }, []);
  return (
    <Navbar bg="light" expand="xxl">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} height="50" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="mr-auto">{amplify}</Nav>
        </Navbar.Collapse>
      </Container>
      {loginComponent}
    </Navbar>
  );
};

export default Menu;
