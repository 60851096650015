import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Form, Modal, Button } from "react-bootstrap";
import "@aws-amplify/ui-react/styles.css";

const Login = () => {
  const [amplify, setAmplify] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    fetch("/static/cognito.json", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("stack:", json);
        Amplify.configure(json);
        setAmplify(true);
      });
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {amplify ? (
          <Authenticator
            signUpAttributes={["email", "website", "company"]}
            loginMechanisms={["username", "email"]}
            components={{
              SignUp: {
                FormFields() {
                  const { validationErrors } = useAuthenticator();

                  return (
                    <>
                      {/* Re-use default `Authenticator.SignUp.FormFields` */}
                      <Authenticator.SignUp.FormFields />

                      {/* Append & require Terms & Conditions field to sign up  */}
                      <Form.Check
                        name="terms"
                        id="terms"
                        label="I agree with the terms and conditions"
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                      />
                    </>
                  );
                },
              },
            }}
            services={{
              async validateCustomSignUp(formData) {
                console.log(formData);
                if (formData.terms != "on") {
                  return {
                    acknowledgement: "You must agree to the Terms & Conditions",
                  };
                }
              },
            }}
          >
            {({ signOut, user }) => {
              document.cookie =
                "idToken=" +
                user.signInUserSession.idToken.jwtToken +
                "; SameSite=Lax; path=/;";
              localStorage.setItem(
                "groups",
                user.signInUserSession.idToken.payload["cognito:groups"]
              );
              window.location = "/protected/index.html";

              return <Button onClick={signOut}>Sign out</Button>;
            }}
          </Authenticator>
        ) : (
          <Button
            variant="outline-success"
            onClick={() => {
              setShow(true);
            }}
          >
            Login
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default Login;
