import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = () => {
    <footer className="footer mt-auto py-3 bg-light">
	<Container className="py-5">
	    <Row>
		<Col className="text-center">
		    <p>&copy; {new Date().getFullYear()} MatchIT. All rights reserved.</p>
		</Col>
	    </Row>		  
	</Container>
    </footer>
};

export default Footer;
