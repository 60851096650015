import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, Alert } from "react-bootstrap";
import Markdown from "marked-react";
import { WithContext as ReactTags } from "react-tag-input";
import "bootstrap/dist/css/bootstrap.min.css";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";

import ContactForm from "./ContactForm.js";

const Profile = () => {
  const [cv, setCv] = useState(<div></div>);
  const [questions, setQuestions] = useState([]);
  const [submitState, setSubmitState] = useState(true);

  useEffect(() => {
    const url = new URL(window.location);
    const profileId = url.pathname.split("/").pop();
    console.log("profile id", profileId);

    fetch(`/data/${profileId}.md`)
      .then((response) => response.blob())
      .then((blob) => blob.text())
      .then((text) => setCv(<Markdown>{text}</Markdown>))
      .catch((err) => {
        console.log("md load", err);
      });

    fetch(`/data/${profileId}.json`)
      .then((response) => {
        console.log("resp>", response);
        return response.json();
      })
      .then((json) => {
        console.log("data>", json);

        setQuestions(json);
      })
      .catch((err) => {
        console.log("json load", err);
      });

    fetch("/static/cognito.json", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("stack:", json);
        Amplify.configure(json);
        Auth.currentAuthenticatedUser()
          .then((user) => {
            setSubmitState(false);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }, []);

  return (
    <Container className="py-5">
      <Row>
        {submitState ? (
          <Alert variant="primary">
            Login first to be able to contact the candidate.
          </Alert>
        ) : (
          <ContactForm questions={questions} />
        )}
      </Row>
      <Row>{cv}</Row>
    </Container>
  );
};

export default Profile;
