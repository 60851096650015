import React, { Component } from "react";
import Menu from "./Menu";
import ProductPage from "./ProductPage";
import Login from "./Login";
import Footer from "./Footer";
import Profile from "./Profile";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Menu />
        <Routes>
          <Route index path="/" element={<ProductPage />} />
          <Route path="/login" element={<Login />} />
          <Route path=":profileId" element={<Profile />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
