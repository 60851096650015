import React, { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import { WithContext as ReactTags } from "react-tag-input";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
} from "react-bootstrap";

import "./react-tag.css";
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const TECHS = [
  "Spring Boot",
  "NumPy",
  "Vue.js",
  "R",
  "Python",
  "Continuous integration/continuous delivery (CI/CD) tools",
  "webDevelopment",
  "Switches",
  "Alibaba Cloud",
  "Android",
  "D3.js",
  "Personal computers",
  "Intrusion prevention systems (IPS)",
  "DNS servers",
  "Mobile development tools",
  "JavaScript",
  "Angular",
  "Perl",
  "Machine learning tools",
  "Smartphones",
  "Redis",
  "Lua",
  "Elasticsearch",
  "Scikit-learn",
  "MongoDB",
  "DynamoDB",
  "Dart",
  "Amazon Web Services (AWS)",
  "Keras",
  "Moment.js",
  "Pandas",
  "Unit testing frameworks",
  "Game development tools",
  "Intrusion detection systems (IDS)",
  "Haskell",
  "PHP",
  "Version control systems (VCS)",
  "Go",
  "macOS",
  "TypeScript",
  "Rust",
  "MySQL",
  "Ruby",
  "Data encryption",
  "Web development tools",
  "Axios",
  "C++",
  "Microsoft Azure",
  "TensorFlow",
  "Tablets",
  "jQuery",
  "Antivirus software",
  "Oracle",
  "Cloud computing infrastructure",
  "Shell",
  "Objective-C",
  "iOS",
  "SciPy",
  "OpenCV",
  "React",
  "Cassandra",
  "Windows",
  "PostgreSQL",
  "Rails",
  "Servers",
  "Node.js",
  "Load balancers",
  "C#",
  "mobileDevelopment",
  "Django",
  "Kotlin",
  "Laravel",
  "Integrated development environments (IDEs)",
  "Swift",
  "Scala",
  "Artificial intelligence tools",
  "MATLAB",
  "Linux",
  "Google Cloud Platform (GCP)",
  "Firewalls",
  "Data science tools",
  "Java",
  "Matplotlib",
  "Microsoft SQL Server",
  "Lodash",
  "Routers",
  "Three.js",
  "PyTorch",
  "Laptops",
  "Groovy",
  "spaCy",
  "ASP.NET Core",
  "git",
  "gitlab",
  "github",
  "docker",
  "terraform",
  "ansible",
  "puppet",
  "salt",
  "AWS cdk",
  "AWS sam",
  "AWS",
];

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const suggestions = TECHS.map((tech) => {
  return {
    id: tech.replace(/ /g, ""),
    text: tech,
  };
});

const onSubmit = (data) => {
  console.log("submit**************************************************", data);
  fetch();
};

const ContactForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm();

  console.log("error", errors);

  const CreateTags = (question, index) => {
    console.log("creating tags", question, index);
    const [tagsData, setTagsData] = useState([]);
    question.data = tagsData;

    return (
      <Controller
        control={control}
        name={question.name}
        render={({ field }) => {
          field.value = () => {
            return tagsData;
          };
          return (
            <ReactTags
              tags={tagsData}
              suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={(i) => {
                const newTags = tagsData.filter((tag, index) => index !== i);
                field.onChange(newTags);
                setTagsData(newTags);

                return newTags;
              }}
              handleAddition={(tag) => {
                console.log("adding", tagsData);
                tagsData.push(tag);
                field.onChange(tagsData);
                setTagsData(tagsData);
                return tagsData;
              }}
              handleDrag={(tag, currPos, newPos) => {
                const newTags = tagsData.slice();
                newTags.splice(currPos, 1);
                newTags.splice(newPos, 0, tag);
                field.onChange(newTags);
                return newTags;
              }}
              handleTagClick={() => {
                console.log("click");
              }}
              inputFieldPosition="top"
              autocomplete
            />
          );
        }}
      />
    );
  };

  const CreateInput = (question, index) => {
    return (
      <input
        {...register(question.name, question.props ? question.props : {})}
        type={question.type}
      />
    );
  };

  const CreateRange = (question, index) => {
    return (
      <div>
        {question.min}
        <input
          {...register(question.name, question.props ? question.props : {})}
          type={question.type}
          min={question.props.min}
          max={question.props.max}
        />
        {question.max}
        <br />
        {watch(question.name)}
      </div>
    );
  };

  const CreateQuestions = (questions) => {
    return (
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {questions.map((question, index) => {
              let component;
              switch (question.type) {
                case "tags":
                  component = CreateTags(question, index);
                  break;

                case "range":
                  component = CreateRange(question, index);
                  break;
                default:
                  component = CreateInput(question, index);
              }
              return (
                <Form.Group as={Row} key={index} className="mb-3">
                  <Col xs={2}>
                    <Form.Label>{question.label}</Form.Label>
                  </Col>
                  <Col>{component}</Col>
                </Form.Group>
              );
            })}
            <Button variant="primary" type="submit">
              Contact
            </Button>
          </Form>
        </Card.Body>
      </Card>
    );
  };

  return <div>{CreateQuestions(props.questions)}</div>;
};

export default ContactForm;
