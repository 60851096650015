import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <div>
      <Container className="bg-white py-5">
        <Row>
          <Col>
            <h1 className="text-center my-5 fw-bold">The firewall to your résumé.</h1>
            <p className="text-center my-5">
		Filter access to your C.V. for relevant recruiters.
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={6} className="mb-3 mb-md-0">
            <Image src="https://via.placeholder.com/500x300" fluid />
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Text>
                    Tired of getting java offers because you do javascript? MatchIT check recuiter konws the difference.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                  <Card.Text>
		      I am looking for a senior dev with 20 years XP on golang > /dev/null
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={6}>
            <Card>
              <Card.Body>
                  <Card.Text>
		      C++ is the new version of C?
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                <Card.Text>
		    
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Image src="https://via.placeholder.com/500x300" fluid />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App;
